@import '@assets/styles/colors';
@import '@assets/styles/animation';

#meetparker-widget {
  .custom-widget-launcher,
  .widget-launcher {
    align-self: flex-end;
    border: 0;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    cursor: pointer;
    margin: 0 20px 10px 0;
    bottom: 0;
    position: fixed;
    right: 0;
    z-index: 9999;
    padding: 0px;
    // background: $primary-color;
    // box-shadow: 0 1px 6px 0 rgba($color: $black-color, $alpha: 0.06),
    //   0 2px 32px 0 rgba($color: $black-color, $alpha: 0.16);

    &.launcher-close {
      @include animation(0s, 6s, float, infinite);
    }

    &:focus {
      outline: none;
    }

    .close-icon,
    .open-icon {
      opacity: 0;
      transform: rotate(-60deg);
      font-size: 32px;
      height: 0px;
      color: $white-color;
      transition: transform 0.3s ease, opacity 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      svg path {
        stroke: $white-color;
      }
    }

    .bot-avatar-icon {
      height: 0px;
      width: 100%;
      background-size: 0% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      opacity: 0;
      transform: rotate(-60deg);
      transition: transform 0.3s ease, opacity 0.3s ease;
      border-radius: 50%;
    }

    &.launcher-open .open-icon {
      opacity: 1;
      transform: rotate(0deg);
      height: 30px;
    }

    &.launcher-close .close-icon {
      opacity: 1;
      transform: rotate(0deg);
      height: 30px;
    }

    &.launcher-close .bot-avatar-icon {
      opacity: 1;
      transform: rotate(0deg);
      height: 100% !important;
      background-size: 100% !important;
    }
  }

  .custom-widget-launcher {
    border-radius: unset;
  }
}

@media screen and (max-width: 767px) {
  #meetparker-widget {
    .custom-widget-launcher.launcher-open,
    .widget-launcher.launcher-open {
      display: none;
    }
  }
}
