@import '@assets/styles/colors';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

#meetparker-widget {
  .widget-container {
    height: 600px;
    width: 400px;
    color: $black-color;
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px;
    line-height: normal;
    bottom: 10px;
    position: fixed;
    margin: 0 20px 80px 0;
    right: 0;
    z-index: 9999;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba($color: $black-color, $alpha: 0.1) 0px 5px 20px;

    &.widget-open {
      opacity: 1;
      transform: translateY(0px);
      transition: opacity 0.5s ease, transform 0.5s ease, height 0.00001s ease;
    }

    &.widget-close {
      height: 0px !important;
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 0.5s ease, transform 0.5s ease,
        height 0.00001s ease 0.5s;
    }
  }
}

.popupbox {
  &.is-active {
    z-index: 10000 !important;
  }
}

.popupbox-wrapper {
  width: 100%;
  max-height: 95%;
}

.popupbox-content {
  overflow: auto !important;
}

@media screen and (max-width: 767px) {
  #meetparker-widget {
    .widget-container {
      width: 100%;
      height: 100%;
      border-radius: 0px;
      margin: 0px;
      bottom: 0px;
      margin-bottom: 0px !important;
    }
  }
}

@media screen and (max-height: 700px) and (min-width: 768px) {
  #meetparker-widget {
    .widget-container {
      height: calc(100% - 100px);
    }
  }
}
