@import '@assets/styles/common';
@import '@assets/styles/animation';

.message-typing {
  @include animation(0s, 0.5s, is-typing, infinite, alternate);
  animation-delay: 0.3s;
  position: relative;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  margin: 0px 10px;
  display: inline-block;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::after {
    left: 10px;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: currentColor;
    color: currentColor;
    @include animation(0s, 0.5s, is-typing, infinite, alternate);
    animation-delay: 0.5s;
  }

  &::before {
    left: -10px;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: currentColor;
    color: currentColor;
    @include animation(0s, 0.5s, is-typing, infinite, alternate);
    animation-delay: 0s;
  }
}
