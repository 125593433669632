@import '@assets/styles/colors';
@import '@assets/styles/common';
@import 'emoji-mart/css/emoji-mart.css';

#meetparker-widget {
  .emoji-wrapper {
    position: relative;
    display: inline-block;
    align-self: center;
    margin-top: 8px;

    .emoji-button {
      .emoticon-icon {
        padding-left: 8px;
        padding-right: 8px;
        margin-top: 4px;
        color: $dark-gray-color;
        font-size: 24px;
        align-self: center;
        cursor: pointer;
        box-sizing: initial;

        &.active {
          color: $primary-color;
        }
      }
    }

    .emoji-content {
      @include card-style;

      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: -250px;
      left: -260px;
      width: 285px;
      transform: translate(0, 10px);
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      display: flex;
      flex-direction: column;

      .emoji-mart-scroll {
        height: 220px !important;
      }

      &.visible {
        z-index: 999999;
        opacity: 1;
        visibility: visible;
        transform: translate(0, -20px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      }

      .emoji-mart {
        width: 285px !important;
        border: none;
        border-radius: 4px;

        .emoji-mart-bar {
          display: none;
        }

        .emoji-mart-category-label {
          text-transform: uppercase;
          color: $dark-gray-color;
          margin: 8px 0px;
          font-size: 12px;

          span {
            @include default-text-style;
          }
        }

        .emoji-mart-search {
          input {
            border: none;
            border-bottom: 1px solid $very-light-blue-color;
            font-size: 14px;
            @include default-text-style;

            &::placeholder {
              color: $light-gray-color !important;
              font-size: 14px !important;

              @include default-text-style;
            }
          }
        }

        .emoji-mart-emoji {
          &:hover {
            transform: scale(1.1);

            &::before {
              background-color: transparent;
            }
          }

          span {
            width: 30px !important;
            height: 30px !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}
