@import '@assets/styles/common';
@import '@assets/styles/animation';

.message-bubble {
  .text-content {
    margin: 0px !important;
    font-size: 14px !important;

    @include default-text-style;
  }

  &.user {
    padding: 12px;
    font-size: 14px;
    background: $primary-color;
    color: $white-color;
    border-radius: 5px;
    max-width: 200px;
    margin-bottom: 5px;
    width: fit-content;
    align-self: flex-end;
    @include default-text-style;

    a {
      color: $white-color;
      text-decoration: underline !important;
      @include default-text-style;
    }
  }

  &.agent,
  &.bot {
    padding: 12px;
    font-size: 14px;
    background: $very-light-blue-color;
    border-radius: 5px;
    width: max-content;
    max-width: 200px;
    margin-bottom: 5px;
    @include default-text-style;

    a {
      color: $white-color;
      text-decoration: underline !important;
      @include default-text-style;
    }
  }
}
