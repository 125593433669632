@import '@assets/styles/colors';

@mixin animation(
  $delay,
  $duration,
  $animation,
  $iteration: 1,
  $direction: normal,
  $timing: linear
) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards;
  animation-iteration-count: $iteration;
  animation-direction: $direction;
  animation-timing-function: $timing;
}

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(fade-in) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include keyframes(float) {
  0% {
    // box-shadow: 0px 5px 15px 0px hsla(0, 0%, 0%, 0.6);
    transform: translateY(0px);
  }

  50% {
    // box-shadow: 0px 25px 15px 0px hsla(0%, 0%, 0%, 0.2);
    transform: translateY(-20px);
  }

  100% {
    // box-shadow: 0px 5px 15px 0px hsla(0, 0%, 0%, 0.6);
    transform: translateY(0px);
  }
}

@include keyframes(is-typing) {
  0% {
    background-color: currentColor;
  }
  50%,
  100% {
    --current-color: currentColor;
    background-color: $light-blue-color;
  }
}
