@import '@assets/styles/colors';
@import '@assets/styles/common';

#meetparker-widget {
  .conversation-item {
    display: flex;
    padding: 8px;

    &:hover {
      cursor: pointer;
    }

    .avatar {
      border-radius: 50%;
      margin-right: 12px;
      margin-left: 16px;
      width: 45px !important;
      height: 45px !important;
    }

    .info {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-right: 12px;
      overflow: hidden;

      .title {
        font-family: 'Roboto', sans-serif !important;
        font-size: 16px !important;
        color: $black-color;
        font-weight: 500 !important;
        margin: 0px;
        padding-bottom: 0px !important;

        @include default-text-style;
        @include ellipsis;
      }

      .subtitle {
        font-family: 'Roboto', sans-serif !important;
        font-size: 13px;
        color: $dark-gray-color;
        margin: 0px;

        @include default-text-style;
        @include ellipsis;
      }
    }

    .time {
      font-size: 12px;
      color: $dark-gray-color;
      align-self: center;
      margin-right: 16px;

      @include default-text-style;
    }
  }
}
