@import '@assets/styles/common';
@import '@assets/styles/animation';

.message-file-container {
  display: flex;
  flex-direction: column;

  img {
    cursor: pointer;
  }

  .image-container,
  .file-container {
    margin-bottom: 5px;
    cursor: pointer;
  }

  .image-container img {
    border-radius: 12%;
  }

  &.user {
    .file-container {
      padding: 12px;
      font-size: 14px;
      background: $primary-color;
      color: $white-color;
      border-radius: 5px;
      max-width: 200px;
      margin-bottom: 5px;
      width: fit-content;
      align-self: flex-end;
      @include default-text-style;

      a {
        color: $white-color;
        text-decoration: underline !important;
        @include default-text-style;
      }
    }

    .image-container {
      max-width: 200px;
    }

    .file-container a {
      color: $white-color;
    }
  }

  &.agent,
  &.bot {
    .file-container {
      padding: 12px;
      font-size: 14px;
      background: $very-light-blue-color;
      border-radius: 5px;
      width: max-content;
      max-width: 200px;
      margin-bottom: 5px;
      @include default-text-style;

      a {
        color: $white-color;
        text-decoration: underline !important;
        @include default-text-style;
      }
    }

    .image-container {
      max-width: 200px;
    }

    .file-container a {
      color: $black-color;
    }
  }
}
