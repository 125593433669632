@import './colors';

@mixin widget-screen-style {
  height: 100%;
  border-radius: 12px;
  overflow-y: auto;

  @media screen and (max-width: 767px) {
    height: 100%;
    width: 100%;
    border-radius: 0px;
  }
}

@mixin header-text-style {
  color: $white-color;
  margin: 0px;
}

@mixin card-style {
  box-shadow: rgba($color: $black-color, $alpha: 0.1) 0px 5px 5px;
  border-radius: 4px;
  background: $white-color;
}

@mixin button-style($type) {
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  padding: 8px;

  @if $type == 'solid' {
    border: 1px solid;
    background: $primary-color;
    color: $white-color;
  } @else if $type == 'outline' {
    border: 1px solid $primary-color;
    color: $primary-color;
    background: transparent;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin screen-header-style {
  background: $primary-color;
  min-height: 75px;
  display: flex;
  position: relative;
  overflow: hidden;

  .header-info {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: right 0.5s linear 0.3s, opacity 0.5s linear 0.3s;
    right: 0px;
    opacity: 1;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@mixin screen-header-back-icon-style {
  align-self: center;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white-color;

  &:hover {
    cursor: pointer;
    background: rgba($color: $black-color, $alpha: 0.2);
  }
}

@mixin default-text-style {
  letter-spacing: normal !important;
  text-transform: none !important;
  line-height: normal !important;
  font-family: 'Roboto', sans-serif !important;
}
