@import '@assets/styles/colors';
@import '@assets/styles/common';

#meetparker-widget {
  .conversation-footer {
    display: flex;
    box-sizing: border-box;
    padding: 8px 16px;
    background: $white-color;
    position: relative;
    width: 100%;

    &.hide {
      display: none;
    }

    .input-container {
      border-radius: 20px;
      background: $very-light-blue-color;
      flex: 1;
      padding: 12px 24px;
      align-self: flex-end;
      display: flex;
      align-items: center;

      .text-input {
        width: 100%;
        resize: none;
        outline: none;
        border: none;
        overflow: hidden;
        padding: 0px;
        font-size: 14px;
        background: transparent;
        font-family: 'Roboto', sans-serif !important;
        color: $black-color;
        max-height: 84px;
        height: 20px;
        @include default-text-style;

        &::placeholder {
          color: $light-gray-color !important;
          font-size: 14px !important;
          @include default-text-style;
        }
      }
    }

    .file-input {
      display: none;
    }

    .send-icon,
    .upload-icon {
      padding-left: 8px;
      padding-right: 8px;
      color: $dark-gray-color;
      font-size: 24px;
      align-self: center;
      margin-top: 8px;
      cursor: pointer;
      box-sizing: initial;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
