@import '@assets/styles/common';
@import '@assets/styles/animation';

.message-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .message-button-option {
    @include button-style($type: 'outline');

    align-self: flex-end;
    width: unset !important;
    margin-bottom: 4px;
    font-size: 14px;
    height: 35px;
    box-sizing: border-box;
    @include default-text-style;

    &:hover {
      @include button-style($type: 'solid');
    }

    &.disabled {
      pointer-events: none;
      border-color: #f0f0f0 !important;
      color: #f0f0f0 !important;
    }
  }
}
