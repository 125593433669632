@import '@assets/styles/common';
@import '@assets/styles/animation';

.message-status {
  text-align: center;
  color: $light-gray-color;
  padding: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  @include default-text-style;
}
