@import '@assets/styles/common';

#meetparker-widget {
  .conversation-container {
    @include widget-screen-style;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    .conversation-body-container {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
