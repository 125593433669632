@import '@assets/styles/common';
@import '@assets/styles/vendor/slick-theme.css';
@import '@assets/styles/vendor/slick.css';

#meetparker-widget {
  .conversation-body {
    flex: 1;
    padding: 8px 12px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    background: $white-color;
    width: 100%;
    box-sizing: border-box;

    .timeline {
      text-align: center;
      font-size: 12px;
      color: $light-gray-color;
      margin: 10px 0px;
    }

    .message-list {
      position: absolute;
      padding-left: 12px;
      padding-right: 12px;
      width: 100%;
      right: 0px;
      opacity: 1;
      transition: right 0.5s linear 0.8s, opacity 0.5s linear 0.8s;
      box-sizing: border-box;

      // &.enter {
      //   padding-left: 12px;
      //   padding-right: 12px;
      //   right: 0px;
      //   opacity: 1;
      // }
    }
  }

  .blur {
    opacity: 0.4;
  }
}
