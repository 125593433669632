@import '@assets/styles/colors';
@import '@assets/styles/common';

#meetparker-widget {
  .conversation-list-header {
    @include screen-header-style;

    .header-info {
      .back-icon {
        @include screen-header-back-icon-style;

        .back-arrow {
          font-size: 24px;
        }
      }

      .title {
        font-size: 20px !important;
        color: $white-color;
        margin: 0px;
        align-self: center;
        margin-left: 8px;
        font-weight: 600 !important;

        @include default-text-style;
      }
    }
  }
}
