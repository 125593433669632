@import '@assets/styles/common';
@import '@assets/styles/animation';

#meetparker-widget {
  .news-card {
    @include card-style;

    margin: 0px 15px;
    margin-bottom: 20px;
    min-height: 240px;
    opacity: 0;
    display: block;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    @include animation(0.3s, 0.3s, fade-in);

    .image {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      box-sizing: border-box;
    }

    .content {
      padding: 20px 14px;
      display: block;

      .title {
        font-size: 14px !important;
        margin-bottom: 5px;
        margin-top: 0px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 600 !important;
        @include default-text-style;
      }

      .description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        //color: $dark-gray-color;
        color: rgb(115, 115, 118);
        font-size: 12px !important;
        font-weight: initial !important;
        margin-bottom: 0px;
        margin-top: 10px;
        @include default-text-style;
      }
    }
  }
}
