@import '@assets/styles/common';
@import '@assets/styles/colors';
@import '@assets/styles/animation';

#meetparker-widget {
  .welcome-container {
    @include widget-screen-style;
    background: $white-color;

    .welcome-header-overlay {
      background: $primary-color;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      min-height: 130px;

      .welcome-header {
        padding: 25px 35px;

        .info {
          display: flex;
          justify-content: space-between;

          .welcome-header-logo {
            border-radius: 4px;
            max-width: 80%;
            max-height: 40px;
            height: auto;
          }

          .welcome-close-icon {
            visibility: hidden;
            align-self: center;
            cursor: pointer;
            color: $white-color;
            font-size: 24px;
          }
        }

        .title {
          color: $white-color;
          font-size: 24px !important;
          margin-bottom: 10px;
          font-weight: 500 !important;
          margin-top: 10px;

          @include default-text-style;
        }

        .subtitle {
          color: rgba($color: $white-color, $alpha: 0.6);
          font-size: 14px !important;
          font-weight: initial !important;

          @include default-text-style;
        }
      }
    }

    .welcome-card {
      @include card-style;

      min-height: 120px;
      padding: 26px 0px;
      margin: 0px 15px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      opacity: 0;
      @include animation(0.3s, 0.3s, fade-in);

      &:nth-child(2) {
        margin-top: -20px;
      }

      .header {
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 10px;
        display: flex;

        .bot-avatar-logo {
          border-radius: 50%;
          margin-left: 5px;
          height: 30px !important;
          width: 30px !important;
        }

        .info {
          flex: 1;

          .title {
            margin: 0px;
            font-size: 18px !important;
            font-weight: 600 !important;
            color: $black-color !important;
            padding-bottom: 0px !important;

            @include default-text-style;
          }

          .subtitle {
            color: $dark-gray-color;
            margin: 0px;
            font-size: 12px !important;
            font-weight: initial !important;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            @include default-text-style;
          }
        }
      }

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .footer {
        padding-left: 24px;
        padding-right: 24px;
        margin-top: 10px;

        .action-button {
          @include button-style($type: 'outline');
          height: 35px;
          @include default-text-style;

          &:hover {
            @include button-style($type: 'solid');
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #meetparker-widget {
    .welcome-container {
      .welcome-header-overlay {
        border-radius: 0px;
      }

      .welcome-header .info .welcome-close-icon {
        visibility: visible !important;
      }
    }
  }
}
