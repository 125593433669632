@import '@assets/styles/common';

.autocomplete-message-item {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 12px 8px;
  border-radius: 4px;
  margin: 5px 0;

  &:hover {
    background-color: $primary-color;
    cursor: pointer;
  }
}

.autocomplete-message-text {
  font-size: 14px;
}

.highlight {
  font-weight: bold;
}
