@import '@assets/styles/common';
@import '@assets/styles/animation';

.card-action-item {
  display: block;
  padding: 12px;
  border-top: 1px solid $light-gray-color;
  color: $primary-color;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  position: relative;

  @include default-text-style;

  &.disabled {
    pointer-events: none;
  }

  .card-action-item-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    background: $white-color;
  }
}
