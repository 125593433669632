.widget-game {
    position: absolute;
    top: 60px;
    width: 100%;
    height: 90%;
    overflow: hidden;
}
.widget-game-close{
    position: absolute;
    top: 10px;
    right: 21px;
    color: white
}
