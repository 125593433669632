@import '@assets/styles/common';

.blur {
  opacity: 0.4;
  background: $white-color;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
