@import '@assets/styles/colors';
@import '@assets/styles/animation';
@import '@assets/styles/common';

.popup-message {
  align-self: flex-end;
  border: 0;
  border-radius: 8px;
  height: 120px;
  width: 340px;
  cursor: pointer;
  margin: 0 20px 10px 0;
  bottom: 0;
  position: fixed;
  right: 0;
  z-index: 9999;
  padding: 0px;
  background: $white-color;
  box-shadow: 0 5px 16px 0 rgba($color: $black-color, $alpha: 0.125);
  display: flex;
  flex-direction: column;

  @include animation(0s, 6s, float, infinite);

  // .close-button {
  //   position: absolute;
  //   top: -8px;
  //   left: -8px;
  //   cursor: pointer;
  //   width: 25px;
  //   height: 25px;
  //   text-align: center;
  //   border-radius: 50%;
  //   color: $white-color;
  //   font-weight: bold;
  //   font-size: 18px;
  //   letter-spacing: normal !important;
  //   text-transform: none !important;
  //   line-height: normal !important;
  //   font-family: 'Roboto', sans-serif !important;
  // }

  .intro {
    flex: 1;
    padding: 13px 25px;
    font-family: 'Roboto', sans-serif !important;
    display: flex;
    cursor: default;

    p {
      font-weight: bold;
      margin: 0px !important;
      padding: 0px !important;
      font-size: 14px !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include default-text-style;
    }

    span {
      font-size: 13px !important;
      margin: 0px !important;
      padding: 0px !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include default-text-style;
    }

    .avatar {
      margin-right: 10px;
      align-self: center;
    }
  }

  .action-button {
    text-align: center;
    border-top: 1px solid $very-light-blue-color;
    padding-top: 10px;
    padding-bottom: 10px;
    background: $primary-color;
    border-radius: inherit;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    p {
      margin: 0px !important;
      padding: 0px !important;
      font-size: 14px !important;
      font-weight: bold !important;
      color: $white-color;
      cursor: pointer;

      @include default-text-style;
    }
  }
}

@media screen and (max-width: 767px) {
  .popup-message {
    display: none;
  }
}
