@import '@assets/styles/common';
@import '@assets/styles/animation';

.message-slider-container {
  position: relative;
  width: 100%;
  margin-bottom: 10px;

  .message-slider-next-arrow,
  .message-slider-prev-arrow {
    box-shadow: 0px 2px 8px 0px rgba($color: $black-color, $alpha: 0.1);
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: absolute;
    z-index: 1;
    background: $white-color;
    top: 50%;

    svg {
      font-size: 14px;
    }
  }

  .message-slider-prev-arrow {
    left: -10px;
  }

  .message-slider-next-arrow {
    right: -10px;
  }

  .message-card-container {
    margin-bottom: 5px;
    position: relative;
    width: 100%;

    .card-item-container {
      padding: 4px;
      box-sizing: border-box;

      .card-item {
        @include card-style;

        width: 100%;
        border-radius: 12px;
        min-height: 340px;
        text-decoration: none;
        box-shadow: 0px 2px 8px 0px rgba($color: $black-color, $alpha: 0.1);
        display: inline-flex !important;
        flex-direction: column;
        margin-bottom: 5px;

        .card-cover {
          width: 100%;
          height: 120px;
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
          object-fit: contain;
        }

        .card-body {
          flex: 1;

          .card-title {
            color: $black-color;
            padding-left: 8px;
            padding-right: 8px;
            font-size: 14px;
            margin-bottom: 0px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include default-text-style;
          }

          .card-description {
            padding-left: 8px;
            padding-right: 8px;
            font-size: 12px;
            color: $dark-gray-color;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            flex: 1;

            @include default-text-style;
          }
        }

        .card-action {
          text-align: center;
        }
      }
    }
  }
}
