@import '@assets/styles/colors';
@import '@assets/styles/common';

#meetparker-widget {
  .conversation-user-info-container {
    flex: 1;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    background: $white-color;

    .conversation-user-info-welcome-message {
      box-shadow: 0px 2px 8px 0px rgba($color: $black-color, $alpha: 0.1);
      padding: 24px;
      font-family: 'Roboto', sans-serif !important;
      font-size: 14px;
      margin: 0px;
      margin-bottom: 12px;
      color: $black-color !important;
      font-weight: normal !important;

      @include default-text-style;
    }

    .conversation-user-info-form {
      display: flex;
      flex-direction: column;
      flex: 1;

      .conversation-user-info-input {
        border-radius: 8px;
        padding: 12px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $light-gray-color;
        font-family: 'Roboto', sans-serif !important;
        font-size: 14px;
        margin-bottom: 12px;
        color: $black-color !important;
        font-weight: normal !important;
        @include default-text-style;

        &::placeholder {
          font-family: 'Roboto', sans-serif !important;
          font-size: 14px;
          color: $dark-gray-color !important;
          font-weight: normal !important;
          @include default-text-style;
        }
      }

      .conversation-user-info-button-container {
        flex: 1;
        display: flex;
        align-items: flex-end;

        .conversation-user-info-button {
          padding: 12px;
          width: 100%;
          border-radius: 8px;
          border: 1px solid $primary-color;
          outline: none;
          cursor: pointer;
          font-family: 'Roboto', sans-serif !important;
          font-size: 14px;
          color: $white-color;
          @include default-text-style;
        }
      }
    }
  }
}
