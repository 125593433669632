@import '@assets/styles/colors';
@import '@assets/styles/common';

#meetparker-widget {
  .conversation-header {
    @include screen-header-style;
    position: relative;

    .header-info {
      .back-icon {
        @include screen-header-back-icon-style;

        .back-arrow {
          font-size: 24px;
        }
      }

      .user-info {
        display: flex;
        align-self: center;
        align-items: center;
        flex: 1;
        padding-right: 12px;
        padding-left: 12px;
        background: 2px solid $white-color;

        .profile-image {
          background-repeat: no-repeat;
          background-size: 100%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }

        .name-info {
          margin-left: 8px;

          .profile-name {
            @include header-text-style;

            font-size: 16px;
            font-weight: 600;
            padding: 0px !important;
            @include default-text-style;
          }

          .profile-status {
            @include header-text-style;

            font-size: 12px;
            color: rgba($color: $white-color, $alpha: 0.8);
            @include default-text-style;
          }
        }
      }
    }
  }
}
