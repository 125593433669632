@import '@assets/styles/common';
@import '@assets/styles/animation';

.conversation-bubble {
  display: flex;

  &.user {
    flex-direction: row-reverse;

    .message-avatar {
      display: none;
    }
  }

  &.agent,
  &.bot {
    + .bot {
      .message-avatar {
        visibility: hidden;
      }
    }
  }

  /* Hides the button if message is not the last message */
  &.is-button:not(:last-child).button-has-title {
    .message-button-container {
      .message-button-option {
        display: none;
      }
    }
  }

  &.is-button:not(:last-child).button-has-no-title {
    display: none;
  }
}
