@import '@assets/styles/common';

#meetparker-widget {
  .conversation-list {
    @include widget-screen-style;

    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .conversation-list-body {
      flex: 1;
      overflow-y: auto;
      background: $white-color;

      .conversation-list-items {
        position: absolute;
        right: -30%;
        width: 100%;
        box-sizing: border-box;
        opacity: 0;
        transition: right 0.5s linear 0.3s, opacity 0.5s linear 0.3s;

        &.enter {
          opacity: 1;
          right: 0px;
        }

        .conversation-item {
          &:first-child {
            margin-top: 0px;
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
