@import '@assets/styles/common';

.autocomplete-message {
  position: absolute;
  bottom: 100%;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 5%;
  padding: 12px;
  opacity: 0.92;
  background: $white-color;
}
